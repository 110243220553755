.price-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 18px;
}

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 4px;
}

.slider__track {
  background-color: #e4eae4;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: var(--main-green);
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
  top: 8px;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #ffffff;
  border: 3px solid var(--main-green);
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.price-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .horizontal-line {
    width: 12px;
    border-bottom: 1px solid #e2e5eb;
    margin: 0 7px;
  }

  input {
    max-width: 100px;
    height: 34px;
    border: 1px solid #e2e5eb;
    border-radius: 6px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #29263a;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.second-price-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0 20px 0;

  span {
    color: var(--main-text);
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    padding: 8px 5.5px;
    border-radius: 6px 0 0 6px;
    border: 1px solid var(--divider);
    background: #e7f0e7;
  }

  .horizontal-line {
    width: 12px;
    border-bottom: 1px solid #e2e5eb;
    margin: 0 7px;
  }

  input {
    max-width: 100px;
    width: 70px;
    height: 35px;
    border: 1px solid #e2e5eb;
    border-left: none;
    border-radius: 0 6px 6px 0;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #29263a;
    outline: none;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.price-text {
  display: flex;
  justify-content: space-between;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #29263a;
  margin-top: 10px;
}
