.input-products {
  position: relative;
  display: flex;
  border: 1px solid #E2E5EB;
  padding: 3px;
  border-radius: 6px;

  align-items: center;
  input {
    padding: 0;
    outline: none;
    width: 100%;
    border: none;
    padding-left: 20px;
    &::placeholder {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #ACADB9;
    }
  }

  .label {
    display: flex;
    padding: 5px 15px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #F5F7FB;
    margin-right: 2px;
    cursor: pointer;
    flex-shrink: 0;
    border-radius: 6px;

    .name {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      text-align: center;
      font-size: 14px;
      line-height: 24px;
      color: #29263A;
    }

    img {
      transform: rotate(180deg);
      margin-right: 18px;
    }
  }

  .controls {
    position: absolute;
    display: flex;
    align-items: center;
    right: 3px;
    top: 3px;

    .search-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--main-green);
      max-height: 32px;
      border-radius: 6px;
      padding: 9px;
      cursor: pointer;
    }
  }
}