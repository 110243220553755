.account-info-map {
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
  margin-bottom: 78px;

  .account-info {
    width: 100%;
    background: #F5F7FB;
    border-radius: 12px;
    padding: 20px 20px 20px 34px;
    margin: 0 19px 0 106px;

    .user {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .user-info {
        display: flex;
        align-items: center;

        .logo {
          width: 90px;
          height: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #E2E5EB;
          margin-right: 20px;
          border-radius: 6px;
        }

        .text {

          .name {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #29263A;
            margin-bottom: 5px;
          }

          .role {
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #ACADB9;
            margin-bottom: 10px;
          }

          .rating {
            display: flex;
            align-items: center;

            .stars {
              margin-right: 6px;
            }

            .reviews {
              font-family: 'Lato', sans-serif;
              font-style: normal;
              font-weight: 700;
              font-size: 12px;
              line-height: 24px;
              color: #29263A;

              span {
                color: #3664F6;
              }
            }
          }

        }
      }

      button {
        display: flex;
        align-items: center;
        padding: 8px 20px 8px 18px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
        background-color: #3664F6;
        border: none;
        border-radius: 6px;
        cursor: pointer;

        img {
          margin-right: 12px;
        }
      }
    }

    .horizontal-line {
      width: 100%;
      border-bottom: 1px solid #E2E5EB;
      margin: 22px 0;
    }

    .info {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .about-us {
        .title {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 24px;
          color: #29263A;
          margin-bottom: 10px;
        }

        .description {
          max-width: 340px;
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #29263A;
        }
      }
    }

    .time, .time-second {
      .title {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        color: #29263A;
        margin-bottom: 10px;
      }

      .schedule {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 22px;
        color: #29263A;

        .day {
          font-weight: 700;
          margin-right: 12px;
        }

        .hours {
          font-weight: 400;
          color: #5A5670;
        }
      }

    }

    .time-second {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: self-end;

      .status {
        margin-bottom: 12px;
      }
    }

    .status {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 73px;
      background: rgba(124, 204, 103, 0.15);
      border-radius: 4px;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 20px;
      color: #7CCC67;

      span {
        margin-right: 6px;
      }
    }
  }
}