.review-card {
  //max-width: 262px;
  padding: 14px 20px 16px 20px;
  background-color: #F5F7FB;
  border-radius: 6px;
  margin-right: 20px;

  .card {
    .text {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #29263A;
      margin-bottom: 32px;

      span {
        color: var(--main-green);
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .user {
        display: flex;
        align-items: center;

        .account-icon {
          margin-right: 12px;
        }

        .name {
          margin-right: 21px;
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 24px;
          color: #29263A;
        }
      }
    }
  }
}