.reviews-section {
  margin: 0 100px 78px 106px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    .title {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 24px;
      color: #29263A;
    }

    button {
      display: flex;
      align-items: center;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      padding: 8px 15px;
      font-size: 14px;
      line-height: 24px;
      color: #3664F6;
      background-color: rgba(54, 100, 246, 0.1);
      border-radius: 6px;
      border: none;
      cursor: pointer;

      img {
        width: 15px;
        height: 15px;
        margin-right: 14px;
      }
    }
  }

  .horizontal-line {
    width: 100%;
    border-bottom: 1px solid #E2E5EB;
    margin: 25px 0 30px 0;
  }

  .arrow-left, .arrow-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid #C6D2E9;
    cursor: pointer;

    &:hover {
      background-color: #3664F6;
      img {
        filter: brightness(100);
      }
    }
  }

  .arrow-left {
    position: absolute;
    left: 0;
  }

  .arrow-right {
    position: absolute;
    right: 0;
    img {
      transform: rotate(180deg);
    }


  }

  .slick-slider {
    display: flex;
    align-items: center;
    padding: 0 100px;
  }
}
