.banner {
  position: relative;
  width: 100%;
  height: 100%;

  .logo {
    position: relative;
    width: 100%;
    object-fit: cover;
    max-height: 500px;
  }

  .text {
    position: absolute;
    left: 152px;
    right: 59.51%;
    top: 76px;

    p {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .line_one {
      font-size: 58px;
      line-height: 70px;
      white-space: nowrap;
      color: #FFCC3E;
    }

    .line_two {
      font-size: 50px;
      line-height: 24px;
      white-space: nowrap;
      color: #FFFFFF;
      margin-left: 20px;

      span {
        letter-spacing: 2px;
      }
    }

    .line_three {
      font-size: 58px;
      line-height: 70px;
      color: #FFFFFF;
    }

    .emoji {
      font-size: 40px;
    }

    .mark {
      position: absolute;
      left: 0;
      top: 70px;
    }
  }

  button {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 156px;
    top: 329px;
    background: #3664F6;
    border-radius: 6px;
    padding: 20px 26px 20px 21px;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;

    img {
      margin-right: 12px;
      filter: brightness(100);
    }
  }
}