.products-slider {
  > .slick-list {
    margin-left: -8px;

    .slick-slide {
      padding: 0 8px;

      > div {
        max-width: 295px;
      }
    }

    .slick-track {
      margin-left: 0;
    }
  }
}

.products-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  margin-top: 24px;
  padding-left: 0;
  width: calc(100% - 300px);
  li {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #E2E2E8;
    &.slick-active {
      background: var(--main-green);
    }
    button{
      display: none
    }
  }

  li + li {
    margin-left: 10px;
  }
}

