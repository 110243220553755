.product-card {
  position: relative;
  max-width: 295px;
  max-height: 415px;
  padding: 10px 20px 30px 20px;
  background-color: #ffffff;
  border: 1px solid #E2E5EB;
  border-radius: 12px;
  transition: ease 0.5s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 40px rgba(78, 94, 108, 0.2);
    border: 1px solid transparent;
  }

  .product-label {
    width: 50px;
    text-align: center;
    position: absolute;
    top: 19px;
    left: -8px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 1px 0;
    border-radius: 2px;
  }

  .image-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    background-color: #ffffff;

    img {
      max-width: 293px;
      max-height: 220px;
      object-fit: cover;
    }
  }

  .info {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .rating, .amount {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 24px;
    }

    .rating {
      color: #ACADB9;
      margin-right: 5px;
    }

    .amount {
      color: #29263A;
    }

    img {
      margin-right: 10px;
    }
  }

  .title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #29263A;
    margin-bottom: 14px;
  }

  .bottom-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .price {
      .prev-price {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-decoration-line: line-through;
        color: #ACADB9;
        margin-bottom: 5px;
      }

      .current-price {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #29263A;
      }
    }

    .buy-like {
      display: flex;
      align-items: center;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 10px;

        img {
          width: 18px;
          height: 18px;
        }
      }

      .cart {
        background-color: #3664F6;
        transition: ease 0.5s;
        margin-right: 10px;
        cursor: pointer;

        &:hover {
          background-color: #7CCC67;
        }

        img {
          filter: brightness(100);
        }
      }

      .heart {
        background-color: #ffffff;
        border: 1px solid #E2E5EB;
        cursor: pointer;
      }
    }
  }
}
