.checkbox-global {
  display: flex;
  align-items: center;
  position: relative;

  label {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #29263A;
    margin-left: 11px;

    span {
      color: #ACADB9;
      margin-left: 5px;
    }
  }

  .checkmark {
    position: absolute;
    pointer-events: none;
    left: 5px;
    width: 10px;
    height: 10px;
  }

  .show__checkmark {
    opacity: 1;
  }

  .hide__checkmark {
    opacity: 0;
  }
}