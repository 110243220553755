.input-global {
  position: relative;
  width: 100%;

  input {
    padding: 0;
    border-radius: 6px;
    outline: none;
    padding-left: 20px;
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    ::placeholder {
      font-style: normal;

      color: #ACADB9;
    }
  }

  .controls {
    position: absolute;
    display: flex;
    align-items: center;
    right: 3px;
    top: 3px;

    .dropdown {
      display: flex;
      align-items: center;
      background: #F5F7FB;
      max-height: 34px;
      border-radius: 6px;
      margin-right: 2px;
      cursor: pointer;

      .name {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #29263A;
        padding: 5px 22px 5px 19px;
      }

      img {
        transform: rotate(180deg);
        margin-right: 18px;
      }
    }

    .search-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--main-orange);
      border-radius: 6px;
      padding: 9px;
      cursor: pointer;
    }
  }
}