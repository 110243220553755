.card-slider {
  min-width: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .card-slider-prev-btn,.card-slider-next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 6;
    cursor: pointer;
  }

  .card-slider-prev-btn {
    left: 12px;
  }

  .card-slider-next-btn {
    right: 12px;
  }

  .slick-list {
    height: 100%;
  }

  .slick-track {
    height: 100%;
  }
  .slick-slide {
    padding: 0 !important;
    height: 100%;
    div {
      height: 100%;
    }
    img {
      max-height: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}