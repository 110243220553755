.delivery-partners {
  margin: 0 100px 80px 106px;

  .header {
    .title {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 24px;
      color: #29263A;
    }
  }

  .horizontal-line {
    width: 100%;
    border-bottom: 1px solid #E2E5EB;
    margin: 25px 0 30px 0;
  }

  .images {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}