/* Spinner container */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust as needed */
  }
  
  /* Spinner animation */
  .spinner {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px; /* Adjust size */
    height: 50px; /* Adjust size */
    animation: spin 2s linear infinite;
  }
  
  /* Keyframes for spinning animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  